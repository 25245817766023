export const veganData = [
    {
        id: 351,
        name: "Тортиља са печуркама (посно)",
        subtitle: "Тортиља",
        desc: "200г шампињона, микс салата, црвени сос",
        grams: "",
        price: "400",
        image: "https://idzuo.com/menuImages/vegan/salata_sa_pecurkama_u_tortilji.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 352,
        name: "Вегетаријанска салата у тортиљи (посно)",
        subtitle: "Тортиља",
        desc: "Микс салата, аћето балзамико, морска со, маринада, маслиново уље",
        grams: "",
        price: "360",
        image: "https://idzuo.com/menuImages/vegan/vegeterijanska_salata_u_tortilji.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 353,
        name: "Тортиља са комадима туне (посно)",
        subtitle: "Тортиља",
        desc: "Комади туне, црвени лук, микс салата, црвени сос",
        grams: "",
        price: "420",
        image: "https://idzuo.com/menuImages/vegan/tuna_salata_u_tortilji.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 354,
        name: "Фиш бургер у тортиљи",
        subtitle: "Тортиља",
        desc: "Фиш бургер, црвени лук, микс салата, црвени сос",
        grams: "",
        price: "380",
        image: "https://idzuo.com/menuImages/vegan/fish_burger_u_tortilji.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 355,
        name: "Фиш бургер у лепињи",
        subtitle: "Лепиња",
        desc: "Фиш бургер, црвени лук, микс салата, црвени сос",
        grams: "",
        price: "380",
        image: "https://idzuo.com/menuImages/vegan/fish_burger.webp",
        cart: "Додајте у корпу",
    },
];