export const chickenData = [
    {
      id: 51,
      name: "Пилетина у тортиљи",
      subtitle: "Тортиља",
      desc: "150г пилетине, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "400",
      image: "https://idzuo.com/menuImages/chicken/piletina_u_tortilji.webp",
      cart: "Додајте у корпу",
    },
    {
        id: 52,
        name: "Пилетина у лепињи",
        subtitle: "Лепиња",
        desc: "150г пилетине, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
        grams: "",
        price: "400",
        image: "https://idzuo.com/menuImages/chicken/piletina_u_lepinji.webp",
        cart: "Додајте у корпу",
    },
    
    {
      id: 53,
      name: "Пилетина са панчетом у тортиљи",
      subtitle: "Тортиља",
      desc: "150г пилетине, панчета, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви ",
      grams: "",
      price: "450",
      image: "https://idzuo.com/menuImages/chicken/piletina_panceta_u_tortilji.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 54,
      name: "Пилетина са панчетом у лепињи",
      subtitle: "Лепиња",
      desc: "150г пилетине, панчета, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "450",
      image: "https://idzuo.com/menuImages/chicken/piletina_panceta_u_lepinji.webp",
      cart: "Додајте у корпу",
    },

    {
      id: 55,
      name: "Пилетина кватро формађи у тортиљи",
      subtitle: "Тортиља",
      desc: "150г пилетине, панчета, quattro formaggi сос, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "500",
      image: "https://idzuo.com/menuImages/chicken/piletina_quattro_formagio.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 56,
      name: "Пилетина кватро формађи у лепињи",
      subtitle: "Лепиња",
      desc: "150г пилетине, панчета, quattro formaggi сос, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "500",
      image: "https://idzuo.com/menuImages/chicken/piletina_quattro_formagio_u_lepinji.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 57,
      name: "Пилетина кватро формађи на тањиру",
      subtitle: "Тањир",
      desc: "250г пилетине, панчета, quattro formaggi сос, микс салата, помфрит, ½ тортиље, сосеви",
      grams: "",
      price: "600",
      image: "https://idzuo.com/menuImages/chicken/piletina_quattro_formagio_na_tanjiru.webp",
      cart: "Додајте у корпу",
    },

    {
      id: 58,
      name: "Чикен бургер у лепињи",
      subtitle: "Лепиња",
      desc: "150г паниране пилетине, фета сир, микс салата, сосеви",
      grams: "",
      price: "390",
      image: "https://idzuo.com/menuImages/chicken/chicken_burger.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 59,
      name: "Чикен чизбургер у лепињи",
      subtitle: "Лепиња",
      desc: "150г паниране пилетине, 2x трапист сир, фета сир, микс салата, сосеви",
      grams: "",
      price: "450",
      image: "https://idzuo.com/menuImages/chicken/cheese_burger_pileci.webp",
      cart: "Додајте у корпу",
    },
  ];
  
  
  