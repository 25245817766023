import React, { useState } from "react";
import { motion, cubicBezier } from "framer-motion";
import { Link } from "react-router-dom";
import gsap from "gsap";
import emailjs from "emailjs-com";

//components
import Header from "../components/header";
import Footer from "../components/footer";
//Images
import heroImg from "../assets/menu-img-05.jpg";
import img1 from "../assets/menu-img-01.jpg";
import map1 from "../assets/map-01.jpg";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";
import { ReactComponent as MapPin } from "../assets/map-pin.svg";

gsap.registerPlugin();

//Default transition
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 0.8, ease: easing };

const Contact = () => {
  const [status, setStatus] = useState("Проследите поруку");
  const [notice, setNotice] = useState(null);

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "idzuo_smtp",
        "idzuo_contact",
        e.target,
        "user_CjIN1unZCq5AWXrLBwCBO"
      )
      .then(
        (result) => {
          setNotice("Порука послата");
          setStatus("Проследите поруку");
          gsap.to(".notice", {
            opacity: 1,
          });
          gsap.from(".notice", {
            opacity: 0,
            delay: 4,
          });
        },
        (error) => {
          setNotice("Грешка у слању");
          gsap.to(".notice", {
            opacity: 1,
            delay: 1,
          });
          gsap.from(".notice", {
            opacity: 0,
            delay: 4,
          });
        }
      );
    setStatus("Порука се шаље");
    e.target.reset();
  }

  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="animate"
        exit={{
          opacity: 0,
          transition: {
            duration: 0.6,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        className="content has-bottom-border"
      >
        <div className="hero-section">
          <motion.span
            initial={{
              left: 0,
              opacity: 0,
            }}
            animate={{
              left: 60,
              opacity: 1,
              transition: { delay: 0.9, ...transition },
            }}
            className="hero-back"
          >
            <Link to="/">
              <ArrowLeft />
            </Link>
          </motion.span>
          <motion.h2
            initial={{
              y: 30,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { delay: 0.8, ...transition },
            }}
            className="hero-title"
          >
            Контакт
          </motion.h2>
          <motion.div
            initial={{
              width: "calc(100% - 120px)",
              height: "calc(100vh - 360px)",
            }}
            animate={{
              width: "100%",
              height: window.innerWidth > 1440 ? 500 : 350,
              transition: { delay: 0.2, ...transition },
            }}
            className="hero-img-holder"
          >
            <motion.img
              initial={{
                opacity: 0.4,
                scale: 1.05,
              }}
              animate={{
                transition: { delay: 0.2, ...transition },
              }}
              src={heroImg}
              alt="Идзуо - о нама"
            />
          </motion.div>
        </div>

        <div className="content-in">
          <div className="content-block">
            <motion.h4
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.4 }}
            >
              Контакт телефон
            </motion.h4>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.4 }}
              className="p-2"
            >
              Позовите нас или нам пошаљите поруку.
            </motion.p>
            <motion.a
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="p-3 white fancy-link link-center"
              href="tel:+0654048048"
            >
              <span data-hover="065 4 048 048">065 4 048 048</span>
            </motion.a>
          </div>

          <div
            className="content-img-inner map"
            style={{ height: "450px", maxHeight: "100%" }}
          >
            <MapPin />
            <img className="content-img" src={map1} alt="Ресторан идзуо" />
          </div>

          <div className="content-block">
            <h4
              style={{
                marginBottom: "15px",
              }}
            >
              Локација
            </h4>
            <p className="p-2">Ресторан Идзуо, Вишњићева 3, 34000 Крагујевац</p>
          </div>

          <div className="content-img-inner">
            <img
              className="content-img width-100"
              src={img1}
              alt="Ресторан идзуо"
            />
          </div>

          <div className="content-block flex flex-end">
            <div className="margin-right-90">
              <h4>Радно време</h4>
              <p className="p-2">
                Петак - Субота
                <br />
                <span className="white">08:00 – 24:00</span>
              </p>
            </div>
            <div>
              <p className="p-2">
                Недеља
                <br />
                <span className="white">14:00-22:00</span>
              </p>
            </div>
          </div>

          <div className="content-block">
            <div className="notice">{notice}</div>
            <h4>Пошаљите нам поруку</h4>

            <form onSubmit={sendEmail}>
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Име"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  placeholder="Презиме"
                  required
                />
              </div>
              <div>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="Телефон"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Имејл"
                  required
                />
              </div>
              <div style={{ width: "100%" }}>
                <textarea
                  id="message"
                  name="message"
                  rows="10"
                  placeholder="Ваша порука"
                  required
                />
              </div>
              <div>
                <button className="form-button" type="submit">
                  {status}
                </button>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default Contact;
