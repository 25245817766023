import React, { useRef, useEffect, useState } from "react";
//Components
import Modal from "./modal";
import Cart from "./cart";
import Breakfast from "./categories/breakfast";
import Salad from "./categories/salad";
import Chicken from "./categories/chicken";
import Pork from "./categories/pork";
import Beef from "./categories/beef";
import Barbecue from "./categories/barbecue";
import Burgers from "./categories/burgers";
import Sausage from "./categories/sausage";
import Vegan from "./categories/vegan";
import Sides from "./categories/sides";
import Dessert from "./categories/dessert";
import Drinks from "./categories/drinks";
import Offer from "./categories/offer";


//Outside Click
const useClickOutside = (handler) => {
  const domNode = useRef();
  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });
  return domNode;
};

export default function Product() {
  const [showNotice, setShowNotice] = useState(false);
  const [showModal, setShowModal] = useState(false);

  //Get Product ID as Class
  const [activeObject, setActiveObject] = useState(null);
  function getClass(index) {
    return index === activeObject?.id ? "active" : "inactive";
  }
  //Cart
  const [cartItems, setCartItems] = useState([]);

  //Save Cart
  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);


  //Cart Add
  const onAdd = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }]);
    }
  };
  //Cart Remove
  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
    }
  };

  function workingHours() {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const hour = now.getHours();
    //const minute = now.getMinutes();
  
    const isWeekday = dayOfWeek > 0 && dayOfWeek <= 5;
    const isSaturday = dayOfWeek === 6;
    const isSunday = dayOfWeek === 0;
  
    const isWithinWorkingHours =
      (isWeekday && (hour > 7 && hour < 24)) ||
      (isSaturday && (hour > 7 || (hour === 0 && hour < 24))) ||
      (isSunday && (hour > 13 && hour < 22));
  
    const showModal = isWithinWorkingHours;
  
    setShowModal(showModal);
    
  }
  
  

  return (
    <div>
      {/* Product List */}
      <Offer
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Breakfast
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Chicken
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Pork
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Burgers
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Beef
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Barbecue
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Sausage
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />
      <Vegan
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />  
      <Salad
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />    
      <Sides
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />  
      <Dessert
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />   
      <Drinks
        setActiveObject={setActiveObject}
        workingHours={workingHours}
        getClass={getClass}
      />    

      {/* Modal */}
      {showModal ? (
        <Modal
          activeObject={activeObject}
          setShowModal={setShowModal}
          onAdd={onAdd}
          useClickOutside={useClickOutside}
          setShowNotice={setShowNotice}
        />
      ) : null}
      {/* Cart */}
      <Cart
        cartItems={cartItems}
        onAdd={onAdd}
        onRemove={onRemove}
        useClickOutside={useClickOutside}
        showNotice={showNotice}
      />
    </div>
  );
}
