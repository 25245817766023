export const offerData = [
    {
        id: 601,
        name: "Akcija 2x Чикен чизбургер у лепињи + гратис мали помфрит",
        subtitle: "",
        desc: "150г паниране пилетине, 2x трапист сир, фета сир, микс салата, сосеви",
        grams: "",
        price: "900",
        image: "https://idzuo.com/menuImages/chicken/cheese_burger_pileci.webp",
        cart: "Додајте у корпу",
    },
];