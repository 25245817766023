export const sidesData = [
    {
        id: 451,
        name: "Бели сос",
        subtitle: "",
        desc: "50г",
        grams: "",
        price: "50",
        image: "https://idzuo.com/menuImages/sides/beli_sos.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 452,
        name: "Црвени сос",
        subtitle: "",
        desc: "50г",
        grams: "",
        price: "50",
        image: "https://idzuo.com/menuImages/sides/crveni_sos.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 453,
        name: "Чили сос",
        subtitle: "",
        desc: "50г",
        grams: "",
        price: "50",
        image: "https://idzuo.com/menuImages/sides/cili-sos.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 454,
        name: "BBQ сос",
        subtitle: "",
        desc: "50г",
        grams: "",
        price: "60",
        image: "https://idzuo.com/menuImages/sides/bbq-sos.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 460,
        name: "Слатко љути чили",
        subtitle: "",
        desc: "50г",
        grams: "",
        price: "50",
        image: "https://idzuo.com/menuImages/sides/cili-sos.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 461,
        name: "Кватро формађи",
        subtitle: "",
        desc: "50г",
        grams: "",
        price: "60",
        image: "https://idzuo.com/menuImages/sides/beli_sos.webp",
        cart: "Додајте у корпу",
    },    
    {
        id: 455,
        name: "Помфрит мали",
        subtitle: "",
        desc: "200г",
        grams: "",
        price: "180",
        image: "https://idzuo.com/menuImages/sides/pomfrit_mali.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 456,
        name: "Помфрит велики",
        subtitle: "",
        desc: "400г и два соса",
        grams: "",
        price: "300",
        image: "https://idzuo.com/menuImages/sides/pomfrit_veliki.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 457,
        name: "Тортиља са сиром и зачинима",
        subtitle: "",
        desc: "160г и два соса",
        grams: "",
        price: "170",
        image: "https://idzuo.com/menuImages/sides/tortilja_sa_sirom_i_zacinima.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 458,
        name: "Тортиља са сиром, зачинима и панчетом",
        subtitle: "",
        desc: "160г и два соса",
        grams: "",
        price: "220",
        image: "https://idzuo.com/menuImages/sides/tortilja_sa_sirom_i_zacinima.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 459,
        name: "Онион рингс",
        subtitle: "",
        desc: "250г",
        grams: "",
        price: "270",
        image: "https://idzuo.com/menuImages/smash/onion-rings.webp",
        cart: "Додајте у корпу",
    },
];
  
  