import React from "react";
import { NavLink } from "react-router-dom";
import { motion, cubicBezier } from "framer-motion";

//Transiton ease
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 1.2, ease: easing };

const header = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: -30,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: -30,
        opacity: 0,
      }}
      transition={transition}
      className="header"
    >
      <div className="row v-center space-between">
        <div className="logo">
          <h1 style={{ display: "none" }}>Идзуо - ресторанска брза храна</h1>
          <NavLink to="/" exact="true">
            Идзуо
          </NavLink>
        </div>
        <div className="time white ">
          <span>Пон-Суб: 08:00–24:00</span>
          <span>Нед: 14:00-22:00</span>
        </div>
        <div className="phone white">
          <a className="fancy-link" href="tel:065 4 048 048" rel="noreferrer">
            <span data-hover="065 4 048 048">065 4 048 048</span>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default header;
